import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from 'redux-thunk';
import { Provider } from "react-redux"
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import { rootReducer } from "./Store/reducers"

import './index.css'
import 'semantic-ui-css/semantic.min.css'

import App from './App'
import ReactGA from "react-ga4";



// Get Firebase config from env
const config = {
  apiKey: "AIzaSyBJOMquCoxFDC8h3MtS8WKg150NJNYCaKY",
  authDomain: "jarrak-v2.firebaseapp.com",
  databaseURL: "https://jarrak-v2.firebaseio.com",
  projectId: "jarrak-v2",
  storageBucket: "jarrak-v2.appspot.com",
  messagingSenderId: "549794290967",
  appId: "1:549794290967:web:e000c7c8ff21db29cd6b52",
  measurementId: "G-0ZHPJ8C9YB"
}
const rrfConfig = {
}

firebase.initializeApp(config)
firebase.firestore()

const initialState = {}

// const middlewares = [
//   thunk.withExtraArgument(getFirebase)
// ]

const store = createStore(
  rootReducer,
  initialState,
  // compose(
  //   applyMiddleware(...middlewares)
  // )
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // since we are using Firestore
}

ReactGA.initialize("G-0ZHPJ8C9YB");

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)
