import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { Container, Segment } from 'semantic-ui-react'
import NavBar from "./NavBar"

const Timeline = () => {

  // Timeline is loaded from a html file in public dir

  // tried making a module and importing into the component
  // but seems like there are problems dynamically generating the elements for video media

  return (
    <Container className="timeline-page">
      <NavBar />
        <iframe
          src="/timeline.html"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          hspace="0"
          vspace="0"
          height="100%"
          width="100%"
          />
    </Container>
  )
}

export default Timeline
