import React from "react"
import { NavLink } from 'react-router-dom'
import { Container, Segment, List, ListItem } from 'semantic-ui-react'
import NavBar from "./NavBar"

const Home = () => {

  return (
    <div className="home-page">
      <NavBar />

      <div className="splash">
        <h1 className="splash-head">
          Jarrak: Our languages journey
          </h1>
        <h2 className="splash-subhead">
          <em>Paja inyja jarrak tha<u>ng</u>ani-<u>ng</u>arri</em>
        </h2>
      </div>

      <Container className="home-page-content">
        <p>
          <em>Jarrak</em> is a public knowledge bank which captures key milestones for strengthening Aboriginal and Torres Strait Islander languages from the 1970s until now. It has been published to support both on-going advocacy and effective progress for revival and maintenance of Indigenous languages.
      </p>
        <p>
          <em>Jarrak</em> includes a timeline and searchable database, with links to documentary and audio-visual evidence of achievements made in four areas: policy, education, language centres and resources. This site is best viewed using Chrome or Firefox browsers.
        </p>
        <List className="home-links">
          <ListItem as={NavLink} to="/timeline" className="home-link">View the timeline</ListItem>
          <ListItem as={NavLink} to="/search" className="home-link">Search the collection</ListItem>
        </List>
        <p>
          Aboriginal and Torres Strait Islander language programs and workers across the continent often operate in isolation, in under-resourced circumstances, and with languages that are at different stages in the revitalisation process. They have contributed resources to the knowledge bank to support others within the network, providing a means to:
            </p>
        <ul>
          <li>build a record and shared understanding of the history of Indigenous language revitalisation in Australia</li>
          <li>showcase information about ways that ground has been gained and how setbacks have been faced</li>
          <li>assert the expertise, long experience, and collective knowledge of Indigenous organisations, their leaders and supporters</li>
          <li>inform and support those who are new to the network, and joining the ever-growing language revitalisation movement</li>
          <li>educate policymakers and funding bodies, now and into the future.</li>
        </ul>
        <hr className="padded" />
        <p>
          <em>Jarrak</em> means "to talk" in Kija. In Kija, the vowel "a" sounds like the "u" in the English word "but", and the "rr" is rolled and sounds a bit like the "tt" in "butter" when said quickly.
            </p>
        <p>
          The full title of this collection, <em>Paja inyja jarrak tha<u>ng</u>ani-<u>ng</u>arri</em>, has been provided by the <a href="http://klrc.org.au/">Kimberley Language Resource Centre</a> and combines four Kimberley languages:
            </p>
        <ul>
          <li><em>paja</em> means ‘many’ or ‘big mob’ in Walmajarri</li>
          <li><em>inyja</em> means ‘to go’ in Nyikina</li>
          <li><em>jarrak</em> means ‘to talk’ in Kija</li>
          <li><em>tha<u>ng</u>ani-<u>ng</u>arri</em> means ‘with our languages’ in Bunuba</li>
        </ul>
        <hr className="padded" />
        <p>
          This site is managed by <a href="http://www.firstlanguages.org.au">First Languages Australia</a>.  Contributions are welcome, please <a href="mailto:contact@firstlanguages.org.au?subject=timeline">email us</a> for further details.
        <br />
        This  project has been undertaken with the support of the <a href="https://sydney.edu.au/research/centres/sydney-policy-lab.html">Sydney Policy Lab, University of Sydney</a>.
      </p>

      </Container>
    </div>
  )
}

export default Home
