import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import _ from 'lodash'
import { Container, Button, Segment, Image, Input } from 'semantic-ui-react'
import NavBar from "./NavBar"
import { RawHTML } from "./RawHTML"

const Archive = () => {

  let option = { collection: "entries" }
  useFirestoreConnect(option)
  const entries = useSelector(state => state.firestore.ordered.entries)
  return (
    <Container className="archive-page">
      <NavBar />
      <Container>
        {entries && entries.map((data) => (
          <Segment key={data.id} className="content">
            <h2>{data.Headline}</h2>
            <div className="content dont-break-out">
              <RawHTML>{data.Text}</RawHTML>
            </div>
            <div className="media dont-break-out links">

              {data.image && data.image.map(file => (
                <Image
                  key={file.src}
                  src={file.src}
                  alt={file.title}
                  className="resp"
                  size="large" />
              ))}
              {data.doc && data.doc.map(file => (
                  <a key={file.src} href={file.src} title={file.title} target="_blank">Download {file.title} doc</a>
              ))}
              {data.pdf && data.pdf.map(file => (
                  <a key={file.src} href={file.src} title={file.title} target="_blank">Download {file.title} PDF</a>
              ))}
              {data.video &&
                <a key={data.video} href={data.video} title={data.video} target="_blank"> Watch {data.video}</a>
              }
            </div>
          </Segment>
        ))
        }
      </Container>
    </Container>
  )
}

export default Archive
