import React, { useEffect, useLayoutEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { Container, Header, Segment, Image } from 'semantic-ui-react'
import { RawHTML } from "./RawHTML"
import PDFRender from "./PDFRender"
import MediaRender from "./MediaRender"
import LazyLoad from 'react-lazyload';
import { format } from 'date-fns'

const EntryContent = props => {

  const { data, showDownloadLink, showFullPDFs } = props

  return (
    <LazyLoad height={200} offset={100}>
      <Segment className="card fluid">

        <Header as="h2" className="content-header">
          <Header as={Link} to={`/entry/${encodeURI(data.Headline)}`}>{data.Headline}</Header>
        </Header>

        <div className="content meta">
          <p className="date">
            {data.Date}
            {/* {data.Date && format(new Date(data.Date), 'd MMM y')} */}
          </p>
        </div>

        <div className="content dont-break-out">
          <RawHTML>{data.Text}</RawHTML>
        </div>

        <div className="media">
          {data.image &&
            <div className="media-item">
              {data.image.map(file => (
                <Image
                  key={file.src}
                  src={file.src}
                  alt={file.title}
                  className="resp"
                  size="large" />
              ))
              }
            </div>
          }
          {data.doc &&
            <div className="media-item">
              {data.doc.map(file =>
                (
                  <div className="doc-container" key={file.src}>
                    <a href={file.src}>Download {file.title}</a>
                  </div>
                ))
              }
            </div>
          }
          {data.pdf &&
            <div className="media-item">
              {data.pdf.map(file =>
                (
                  <PDFRender
                    key={file.src}
                    src={file.src}
                    showDownloadLink={showDownloadLink}
                    showFullPDFs={showFullPDFs}
                  />
                ))
              }
            </div>
          }
          {data.video &&
            <div className="media-item">
              <MediaRender url={data.video} />
            </div>
          }
        </div>

      </Segment>
    </LazyLoad>
  )
}

export default EntryContent
