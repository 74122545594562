import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import ReactPlayer from 'react-player/lazy'

const MediaRender = props => {

  const { url } = props
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current.offsetWidth)
    setHeight(ref.current.offsetWidth/1.78)
  }, [ref.current]);

  return (
    <div className="media-container" ref={ref}>
      <ReactPlayer
        url={url}
        controls={true}
        width={width}
        height={height}
      />
    </div>
  )
}

export default MediaRender
