import React, { useState, useRef, useEffect } from "react"
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from 'semantic-ui-react'

// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.js"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFRender = props => {
  const { src, showDownloadLink, showFullPDFs } = props

  const [isLoaded, setIsLoaded] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [PDFwidth, setPDFwidth] = useState(0)
  const ref = useRef(0);

  useEffect(() => {
    setPDFwidth(ref.current.offsetWidth)
  }, [ref.current]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setIsLoaded(true)
  }
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function prevPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }



  return (
    <div className="pdf-container" ref={ref}>

      {/* One approach is to load the whole pdf.. */}
      {/* <iframe src={src} width="100%" height="600px"/> */}

      {/* Or.. we can render the front page */}
      <Document
        file={src}
        
        onLoadSuccess={onDocumentLoadSuccess}
        onSourceError={console.error}
        onLoadError={console.error}
      >
        <Page
          pageNumber={pageNumber}
          width={PDFwidth}
        />
      </Document>

      {/* And allow nav through the pdf on detail page */}
      {showFullPDFs && isLoaded &&
        <div className="pdf-tools">
          <Button onClick={prevPage} disabled={pageNumber === 1}>prev</Button>
          <Button onClick={nextPage} disabled={pageNumber === numPages}>next</Button>
          {showDownloadLink &&
            <a className="pdf-download" href={src} target="_blank">Download</a>
          }
        </div>
      }
    </div>
  );

}

export default PDFRender