import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Home from './Components/Home'
import Timeline from './Components/Timeline'
import EntryList from './Components/EntryList'
import Entry from './Components/Entry'
import Archive from './Components/Archive'

const App = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={Home} exact />
      <Route path="/timeline" component={Timeline} />
      <Route path="/search" component={EntryList} />
      <Route path="/archive" component={Archive} />
      <Route path="/entry/:id" component={Entry} />
    </BrowserRouter>
  );
}

export default App;
