import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import _ from 'lodash'
import { Container, Button, Segment, Image, Input } from 'semantic-ui-react'
import NavBar from "./NavBar"
import EntryContent from "./EntryContent"

const EntryList = () => {

  const [searchTerm, setSearchTerm] = useState("")
  const [sortBy, setSortBy] = useState("date")
  const [sortOrder, setSortOrder] = useState("<")
  const [entries, setEntries] = useState([])
  const delayedQuery = useCallback(_.debounce(term => setSearchTerm(term), 500), []);

  let option = { collection: "entries" }
  useFirestoreConnect(option)
  const rawEntries = useSelector(state => state.firestore.ordered.entries)

  const filterEntries = data => {
    let filtered = []

    // Strip punctuation and spaces and convert to lowercase for better string comparison for sorting
    const sort_prep = (str) => {
      return str.replace(/[^\w\s]|_/g, "").replace(/\s+/g, "").toLowerCase();
    }

    // Variable comparison operator technique from https://stackoverflow.com/a/10591359
    const date_operator_table = {
      '>': (a, b) => (a.date > b.date),
      '<': (a, b) => (a.date < b.date)
    }
    const headline_operator_table = {
      '>': (a, b) => (sort_prep(a.Headline) > sort_prep(b.Headline)),
      '<': (a, b) => (sort_prep(a.Headline) < sort_prep(b.Headline))
    }

    if (data) {
      data.map(item => {
        const nameMatch = item.Headline && item.Headline.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false
        const textMatch = item.Text && item.Text.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false
        if (nameMatch || textMatch) {
          // Use the item, but add a date property in JS Date format so we can sort it better
          const myItem = { ...item, "date": new Date(item.Date) }
          filtered = [...filtered, myItem]
        }
      })
      // Sort
      if (sortBy === "date") filtered.sort((a, b) => date_operator_table[sortOrder](a,b) ? 1 : -1)
      if (sortBy === "name") filtered.sort((a, b) => headline_operator_table[sortOrder](a, b) ? 1 : -1)
    }
    return filtered
  }

  useEffect(() => {
    if (rawEntries === entries) return
    setEntries(filterEntries(rawEntries))
  }, [rawEntries, searchTerm, sortBy, sortOrder])

  function changeSearchTerm(e) {
    // TODO sanitise etc
    // setSearchTerm(e.target.value)
    // debounce version from https://medium.com/@rajeshnaroth/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
    delayedQuery(e.target.value)
  }

  function changeSortBy() {
    const newSortBy = (sortBy === "date") ? "name" : "date"
    setSortBy(newSortBy)
  }

  function changeSortOrder() {
    const newSortOrder = (sortOrder === "<") ? ">" : "<"
    setSortOrder(newSortOrder)
  }



  return (
    <Container className="entry-list-page">
      <NavBar />
      <Container text>
        <div className="list-tools">
          <Input
            icon='search'
            placeholder='Search...'
            onChange={changeSearchTerm}
          />
          <div>
          <Button basic onClick={changeSortBy}>
            Sort by
            {sortBy === "date" ? " name" : " date"}
          </Button>
            <Button basic icon={sortOrder === "<" ? "arrow alternate circle down" : "arrow alternate circle up"} onClick={changeSortOrder} />
          </div>
        </div>

        {entries && entries.map((data, i) => (
          <EntryContent
            key={i}
            data={data}
            showDownloadLink={true}
            showFullPDFs={true}
          />
        ))
        }
      </Container>
    </Container>
  )
}

export default EntryList
